@import './colors.css';

.neu {
    border-radius: 25px;
    background: var(--bg);
    box-shadow:  20px 20px 40px var(--bg-shadow),
                 -20px -20px 40px var(--bg-shadow);
}

.modalbox {
    align-items: center;
    background: var(--fg);
    /* border-radius: var(--edge-round-radius); */
    border: 2px;
    border-color: var(--bg);
    border-style: solid;
    font-size: 1rem;
    font-weight: 400;
    justify-content: center;
    padding: 1rem;
    text-align: center;

    margin: 0px 20px 20px 0px;
}