
.embedded-status {
    float: left;
    padding: 0 1rem 1rem 0;
    margin-right: 1rem;
}

.download-panel {
    padding: 2vh;
    display: flex;    
    flex-wrap: wrap;
    gap: 2vw;
    justify-content: space-around;
    align-items: center;
}

.download-panel a{
    height: 75%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh 1vw 1vh 1vw;
    background-color: var(--hl);
    font-weight: 500;
    text-align: center;
    color: var(--bg);
}