@import '../../css/colors.css';

@keyframes spike-move {
    from { transform: translate(0px,0px) }
    to { transform: translate(3vmin,0px) }
}

.spike {
    width: 0; 
    height: 0; 
    border-left: 1.5vmin solid transparent;
    border-right: 1.5vmin solid transparent;
    
    border-top: 2.598vmin solid var(--fg);
}

.spike-row {
    display: flex;
    animation-name: spike-move;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spike-bar {
    margin-left: -40vw;
    width: calc(100% + 60vw);
    overflow-x: hidden;
}

