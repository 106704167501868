@import url(../../../css/colors.css);

@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');

.chf-root {
    background-color: var(--char);
    background-size: cover;
    color: var(--ivory);
    display: flex;
    flex-direction: column;
    font-family: Libre Franklin,sans-serif;
    font-weight: 800;
    justify-content: space-around;
    min-height: 100vh;
    align-items: stretch;
}

.chf-root a {
    color: var(--greem);
}

.chf-rootnav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    align-self: baseline;
}

.chf-return {
    border: inset;
    border-width: 4px;
    border-color: var(--greem);
}

.chf-dl {
    text-align: right;
    border: inset;
    border-width: 4px;
    border-color: var(--greem);
}

.chf-display {
    margin: 5vh 5vw 5vh 5vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.chf-navbar {
    align-items: center;
    background-color: var(--d91c55);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.chf-navbar p {
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
    align-self: flex-end;
    font-size: 0.9rem;
    font-weight: 800;
    color: var(--rose);
}

.chf-navbuttons {
    display: flex;
    width: 70%;
    flex-direction: row;
    justify-content: space-around;
}

.chf-navbuttons button{
    background-color: var(--rose); /* Green */
    border-width: 4px;
    color: var(--greem);
    padding: 0.75vh 1.25vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;

    font-family: "Libre Franklin", sans-serif;
    font-weight: 800;
}

.spread-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.chf-container {
    width: 100vw;
    margin: 0 0.2vw;
    max-width: 60vh;
    height: calc(11/8.5 * var(--cw));
}

.chf-navbutton {
    background: var(--rose);
    color: var(--greem);
    padding: 4px;
    font-weight: 900;
    border-color: var(--greem);
}

.chf-viewmatic-button {
    -webkit-box-shadow: 0px 0px 105px 45px var(--rose);
    -moz-box-shadow: 0px 0px 105px 45px rgba(217,28,82,0.9);
    box-shadow: 0px 0px 105px 45px var(--rose);
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 36px;
    left: 12px;
    top: 12px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #cdcdcd;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: var(--greem);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 36px;
    width: 36px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #cdcdcd;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: var(--char);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: var(--char);
}

/* Wrapper for item list */
.bm-item-list {
    color: var(--ivory);
}

/* Individual item */
.bm-item {
    display: inline-block;
    margin: 4px;
    color: var(--rose);
    background: var(--char)
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.bg {
  pointer-events: none;
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200%;
  background: transparent url('https://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation .2s infinite;
  opacity: .9;
  visibility: visible;
}

@keyframes bg-animation {
    0% { transform: translate(0,0) }
    10% { transform: translate(-5%,-5%) }
    20% { transform: translate(-10%,5%) }
    30% { transform: translate(5%,-10%) }
    40% { transform: translate(-5%,15%) }
    50% { transform: translate(-10%,5%) }
    60% { transform: translate(15%,0) }
    70% { transform: translate(0,10%) }
    80% { transform: translate(-15%,0) }
    90% { transform: translate(10%,5%) }
    100% { transform: translate(5%,0) }
}