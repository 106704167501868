@import "../../../css/colors.css";

.toc {
    position: absolute;
    background: var(--char);
    z-index: 10;
    border-style: solid;
    border-color: var(--char2);
    pointer-events: all;
    padding: 10px;
    padding-top: 0;
}

.top-bar {
    background: var(--char2);
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 0 -10px;
}

.x-button {
    background-color: var(--rose);
    margin: 2px;
    font-size: 8pt;
    font-weight: 900;
    color: var(--greem);
}

.chf-body ul {
    margin-block-start: 0;
    margin-right: 0.5em;
    padding-inline-start: 0;
    list-style: none;
}

.chf-body li {
    background-color: var(--char);
    padding: 6px;
}

.chf-body h1 {
    color: var(--greem)
}