:root {
    --hl: #D8DBE2;
    --accent: #FFA400;
    --text: #B1B1B1;
    --bg: #1A1E23;
    --fg: #830631;

    /* CHF Colors */

    --greem: #c4d930;
    --rose: #d91c55;
    --char: #000000;
    --char2: #444444;
    --ivory: #ffffff;

    --bg-shadow: #111417;
    --bg-light: #23282f;
}

