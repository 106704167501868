@import "../chfissue.css";

.chf-m {
    .chf-display {
        margin: 0vh 1.5vw;
        height: 95vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 0vh;
    }
    
    .chf-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .chf-toc {
        display: none;
    }
    
    .chf-rootnav {
        display: none;
    }
    
    .chf-rootnav-mobile-spacer {
        display: block;
        height: 3em;
    }
    
    .chf-viewer {
        position: relative;
        width: 80%;
    }

    .chf-container {
        width: 100%;
    }

    .spread-container {
        display:block;
        justify-content: none;
        max-width: 60vh;
        width: 99vw;
    }
}

.chf-m-warning {
    background-color: var(--rose);
    text-align: center;
    padding: 2px;
}
