.audio-controls {
    align-content: space-around;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: calc(min(10vmax, 50%));
    align-items: center;
    margin-top: 4px;
}

.audio-controls button {
    background-color: transparent; /* Green */
    border: none;
}

.audio-controls button img {
    width: 100%
}