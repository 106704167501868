@import url(../../css/constants.css);
@import url(../../css/neumorphic.css);


.hamburger {
    display: none; 
}
  
@media screen and (max-width:100vh){
    .hamburger{
        display: inherit
    }
    .nav-bar{
        display: none;
    }
}

.nav-bar {
    padding: 0.5rem;
    height: calc(100% - 5vh - 2rem);
    width: 20vw;
    align-self:flex-start;
}
  
.nav-menu{
    list-style-type: none;
    margin-left: 1rem;
    margin-top: 0;
    padding-left: 0;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
}

.nav-menu li + li {
    margin-top: 16px;
}

/* 2 */
.nav-menu a {
    border: none;
    font-size: 20pt;
    padding: 4px 8px;
    color: var(--text);
    text-decoration: none;
    border-radius: 4px;
  }
.nav-menu a:before {
height: 0%;
width: 2px;
}
.nav-menu a:hover {
box-shadow:  4px 4px 6px 0 var(--bg-light),
            -4px -4px 6px 0 var(--bg-shadow), 
    inset -4px -4px 6px 0 var(--bg-light),
    inset 4px 4px 6px 0 var(--bg-shadow);
}

.nav-menu a.active{
    box-shadow:  4px 4px 6px 0 var(--bg-light),
    -4px -4px 6px 0 var(--bg-shadow),    
inset -4px -4px 6px 0 var(--bg-light),
inset 4px 4px 6px 0 var(--bg-shadow);
    outline: 2px;
    outline-style: solid;
    outline-color: var(--bg-light);
}

