@import './constants.css';

.main {
  background-color: var(--bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--text);
  overflow: hidden;
}

.primary-grid {
  display: grid; 
  grid-template-columns: 0.28fr 1fr; 
  grid-template-rows: 10vh auto 5vh; 
  gap: 0px 0px; 
  grid-template-areas: 
    "header header"
    "nav site-body"
    "footer footer"; 
  margin: 5vh 5vw 5vh 5vw;
}

@media screen and (max-width:768px){
  .primary-grid {
    display: flex; 
    flex-direction: column;
    gap: 10px 10px;
    margin: 5vh 5vw 5vh 5vw;
  }
}


.header { 
  grid-area: header; 
  display: flex;
  flex-direction: column;
}

.title-bar {
  background-color: var(--fg);
  margin: -5vh -5vw 0 -5vw;
  height: 15vmin;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Micro 5", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.nav { 
  margin-top: 5vh;
  grid-area: nav;
}

.footer { 
  grid-area: footer;
}

.site-body { 
  grid-area: site-body; 
  margin: 5vh 1vw 1vh 3vw;
  height: fit-content;
  min-height: 70vh;
}

.top-title {
  font-size: 9vmin;
  text-align: center;
  margin: 0;
}

.subtitle {
  font-size: 4vmin;
  margin-block: 0.25em;
}

.footer {
  text-align: center;
}

.accent-text {
  color: var(--hl);
  font-weight: 500;
}