@import url(./../../css/colors.css);
@import url(./../../css/neumorphic.css);


.kart-panel {
    width: 25%;
    display: flex;
    flex-direction: column;
}
.kart-panel p {
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
}

.ip-panel {
    background-color: var(--bg);
    font-size: 0.90rem;
    color: var(--hl);
    width: 80%;
}


