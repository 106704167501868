@import url(./../../css/colors.css);

.work-panel {
    align-items: center;
    border-radius: var(--edge-round-radius);
    background: var(--fg);
    display: flex;
    flex-direction:column;
    justify-content: center;
    text-align: center;
    padding: 1rem 1rem 1rem 1rem;
    width: 50%;

    font-weight: normal;
    font-size: 1rem;
}

.work-panel p {
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
}

.url-panel {
    background-color: var(--bg);
    font-size: 0.90rem;
    color: var(--hl);
    width: 80%;
    padding: 0.5rem;
}

.url-panel a {
    color: var(--hl);
}
