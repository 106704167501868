@import "../chfissue.css";

.chf-w {
    .chf-viewer {
        margin-left: 1em;
        width: 25vw;
        top: 10vh;
        z-index: 5;
    }
    
    .chf-navbar {
        max-width: 80vw;
    }
    
    .chf-rootnav-mobile {
        display: none;
    }
    
    .chf-rootnav-mobile-spacer {
        display: none;
    }
    
    .chf-body {
        width: 100%;
    }

    .spread-container {
        max-width: 120vh;
    }
}
