@import url(../../../css/colors.css);

/* Full Retheme */

.chf .spike {
    border-top-color: var(--greem);
}

.chf .title-bar {
    background-color: var(--greem);
    color: var(--char);
}


.chf-current {
    border-radius: 1vw;
    background-color: var(--fg);
    margin: 5% 0% 5% 0%;
    padding: 0.5rem;
}   

.current-header {
    font-size: 3rem;
    font-weight: 500;
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
    text-align: center;
    color: var(--rose);

}

.chf-header {
    border-radius: 1vw;
    border-color: var(--rose);
    border-width: 0.5vw;
    border-style: double;
    padding: 0.4em;
    margin-bottom: 1em;
}

.current-title {
    font-size: 2rem;
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
    text-align: center;
}

.blurb-box {
    display: flex;
    flex-direction: row;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.blurb-box img {
    float: left;
    padding: 0 1rem 1rem 0;
    margin-right: 1rem;
    width: 50%;
}

.blurb-text {
    display: flex;
    flex-direction: column;
}

.read-box {
    display: flex;
    direction: row;
    align-items: center;
    justify-content: center;
    border-color: var(--rose);
    border-style: double;
    border-radius: 1vw;
    padding: 1vmin;
}

.read-below{
    background-color: var(--bg); /* Green */
    border: double;
    color: var(--rose);
    padding: 0.75vh 1.25vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
}