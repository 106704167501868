@import "../../../css/colors.css";

.viewmatic {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    z-index: 10;
    background-color: var(--char);
    width: 200px;
    padding: 10px;
    padding-top: 0;
}

.layout {
    display: flex;
    flex-direction: column;
}

.top-bar {
    background: var(--char2);
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 0 -10px;
}

.x-button {
    background-color: var(--rose);
    margin: 2px;
    font-size: 8pt;
    font-weight: 900;
    color: var(--greem);
}