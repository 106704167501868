@import "../../css/colors.css";

.audio-player {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 4px;
}

.progress {
    transform: rotate3d(0, 0, 45);
}

.track-info {
    display: flex;
    flex-direction: column;
    color: var(--greem);
    align-items: end;
    justify-content: space-around;
    width: 100%;
}

.track-info h2 {
    font-size: 1.2rem;
    margin: 0px;
}

.track-info h3 {
    font-size: 1rem;
    margin: 0px;
}

input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */

      /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
  }
  
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}
  
input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
  
input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
  
    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
    overflow: hidden;
}

input[type="range"]::-webkit-slider-runnable-track {
    height: 15px;
    background: var(--ivory);
    border-radius: 16px;
    overflow: hidden;
}
  
  /* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
    height: 15px;
    background: var(--ivory);
    border-radius: 16px;
    overflow: hidden;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 15px;
    width: 15px;
    background-color: var(--rose);
    border-radius: 50%;
    border: 2px solid var(--greem);
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px var(--greem);
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: var(--rose);
    border-radius: 50%;
    border: 1px solid var(--greem);
    /*  slider progress trick  */
}

.track-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
}